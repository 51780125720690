$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/carreisen-ostschweiz/';
@import 'styles/helpers/global_variables';

.modal {
  .header {
    padding-bottom: 0.5rem;
    border: none;

    .title {
      @include font-title-h4(false);
    }
  }

  .body {
    padding-bottom: 0.5rem;
  }

  .contactBlock {
    margin-bottom: 1rem;

    .icon {
      img {
        width: 60px;
        height: auto;
      }
    }

    .link {
      @include font-uppercase-bold;

      a {
        text-decoration: none;
        color: inherit;
      }
    }

    .openingHours {
      font-size: 0.9rem;
    }
  }

  .footer {
    border: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .button {
      @include font-uppercase-bold;
    }
  }
}
